/* Intercom banner navbar at the top */
#txtCart-header {
	position: relative;
	z-index: 10;
	margin-top: 0;
}

#Main-container {
	margin-top: initial;
}

.newLink {
	padding-top: 1px;
	background: rgb(12, 132, 254, 0.1);
	color: #0c84fe;
	width: 50px;
	border-radius: 4px;
	margin-left: 10px;
	font-weight: 700;
	height: 22px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.icons {
	position: relative;
}

.badge {
	border-radius: 100px;
	width: 19px;
	color: #fff;
	font-size: 11px;
	font-weight: 600;
	background: #ff004c;
	position: absolute;
	line-height: normal;
	top: -7px;
	height: 14px;
	left: 5px;
}
