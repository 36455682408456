.react-datepicker-wrapper{
    width: 100% !important;
}
.react-datepicker__month-container{

}
/* .react-datepicker-popper{
} */
.react-datepicker-popper[data-placement^=bottom]{
    padding-top: 0px !important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    border-top: none !important;
    content: none !important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
    content: none  !important;
}
.react-datepicker__header {
    background-color: #fff !important;
    border:none  !important
}
.react-datepicker{
    border-radius: 0px !important;

    border: 1px solid #EBEBEB !important;
    border-top: none !important;
}
.react-datepicker__navigation{
    border: 1px solid #EBEBEB !important;
    border-radius: 8px 0px 0px 8px
}
.react-datepicker__navigation .react-datepicker__navigation--previous{
    border: 1px solid #EBEBEB !important;
}
.react-datepicker__navigation .react-datepicker__navigation--next{
    border: 1px solid #EBEBEB !important;
}