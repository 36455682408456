.mention {
	/* background-color: #f0f0f0; */
	padding: 2px 5px;
	border-radius: 3px;
	margin-right: 5px;
}

.mention-remove {
	border: none;
	background: transparent;
	font-size: 12px;
	cursor: pointer;
	padding: 0 3px;
	margin-left: 3px;
}

.emoji-class .epr-header {
	display: none;
}
.emoji-class .epr_q53mwh {
	/* display: none; */
}
.emoji-class  .EmojiPickerReact{
	height: 300px !important;
	z-index: 99;
}
