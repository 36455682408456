body {
	font-family: "Inter" !important;
}
a {
	text-decoration: none;
}
.innerMain {
}

#sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
}

.ant-table-content::-webkit-scrollbar {
	height: 3px;
	width: 2px; /* Width of the entire scrollbar */
	background: transparent; /* Background color of the track */
}

.ant-table-content::-webkit-scrollbar-thumb {
	height: 2px;
	background: #0c84fe; /* Background color of the thumb */
	border-radius: 4px; /* Rounded corners for the thumb */
}

.setting-tab-selection::-webkit-scrollbar {
	display: none;
}
.Onboarding-modal::-webkit-scrollbar {
	display: none;
}
/* body::-webkit-scrollbar {
	display: none;
} */
.opacity-1 {
	opacity: 1;
}
.topbarSearchInput {
	height: 47px;
	padding: 15px;
	width: 200px;
	border-radius: 7px;
}
#pointerCursor {
	cursor: pointer;
}
#disableCursor {
	cursor: not-allowed;
}
.ant-modal-content {
	padding: 0px !important;
}
.notification-dot {
	position: absolute;
	width: 8px;
	right: 8px;
	height: 8px;
	background-color: #ff004c;
	border-radius: 50%;
	top: 3px;
	z-index: 9;
}
.outer-circle {
	animation: 3s ease 3ms infinite normal both running motion-keyframes-pulse;
	transform-origin: center;
}
.disable-hover {
	pointer-events: none;
}
@keyframes motion-keyframes-pulse {
	0%,
	50% {
		transform: scale(0.85);
		opacity: 1;
	}
	100% {
		transform: scale(2.5);
		opacity: 0;
	}
}
.needhelp {
	color: #00be5b;
	font-size: 12px;
	line-height: 22px;
	opacity: 1 !important;
	font-weight: 500;
	text-decoration: underline;
}
.icons {
	padding-right: 10px;
	fill: blue;
}
.navLinks {
	position: absolute;
	bottom: 10px;
}
.down-arrow-sm {
	padding-left: 10px;
}
.custom-progress-bar {
	font-weight: 600;
}
.fill-blue {
	fill: blue;
}

.badge {
	border-radius: 100px;
	width: 30px;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	background: #ff004c;
	position: absolute;
	right: 10px;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
	padding: 0 !important;
}

.navigation-list {
	list-style: none;
	padding: 0;
}

.navigation-item {
	cursor: pointer;
	position: relative;
}

.arrow-indicator {
	display: none;
	position: absolute;
	top: 50%;
	left: 100%;
	transform: translateY(-50%);
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid black;
}

.navigation-item.open .arrow-indicator {
	display: block;
}
.myActive {
	position: relative;
}

.myActive::before {
	display: none;
	content: "";
	border-left: 1.5px solid #394c64;
	border-bottom-left-radius: 8px;
	/* left: 7.6%; */
	/* border-bottom: 1.5px solid #394c64; */
	width: 13px;
	position: absolute;
	left: 7.6%;
	top: 35px;
	z-index: 9;
}
.myActive::before {
	display: block;
}

.arrow-head::before {
	content: "";
	width: 8px;
	left: 22px;
	transform: rotate(-36deg);
	display: block;
	position: absolute;
	height: 8px;
	border-right: 1.5px solid #394c64;
	bottom: 15px;
	border-bottom: 1.5px solid #394c64;
	z-index: 99;
}

.button-active::before {
	/* content: url("data:image/svg+xml,%3Csvg%20width%3D%2211%22%20height%3D%2225%22%20viewBox%3D%220%200%2011%2025%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20opacity%3D%220.2%22%20d%3D%22M10.3536%2021.3536C10.5488%2021.1583%2010.5488%2020.8417%2010.3536%2020.6464L7.17157%2017.4645C6.97631%2017.2692%206.65973%2017.2692%206.46447%2017.4645C6.2692%2017.6597%206.2692%2017.9763%206.46447%2018.1716L9.29289%2021L6.46447%2023.8284C6.2692%2024.0237%206.2692%2024.3403%206.46447%2024.5355C6.65973%2024.7308%206.97631%2024.7308%207.17157%2024.5355L10.3536%2021.3536ZM0.5%200V15H1.5V0H0.5ZM7%2021.5H10V20.5H7V21.5ZM0.5%2015C0.5%2018.5899%203.41015%2021.5%207%2021.5V20.5C3.96243%2020.5%201.5%2018.0376%201.5%2015H0.5Z%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E"); */
	position: absolute;
	top: -3px;
	left: 14px;
}

.show-arrow::before {
	content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='45' height='25' fill='none'%3E%3Cpath fill='%23B5B5B5' d='M9 0h1.5v28H9z'/%3E%3C/svg%3E");
	opacity: 1;
	position: absolute;
	/* left: 18px; */
}

.show-arrow-parent::before {
	content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2721%27 height=%2728%27 fill=%27none%27%3E%3Cpath fill=%27%23B5B5B5%27 d=%27M9 24.75a.75.75 0 0 1 1.5 0V28H9v-3.25Z%27/%3E%3C/svg%3E");
	/* content: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 13C6.55228 13 7 13.4477 7 14V20C7 20.5523 6.55228 21 6 21C5.44772 21 5 20.5523 5 20V14C5 13.4477 5.44772 13 6 13Z" fill="black"/></svg>'); */
	opacity: 1;
	position: absolute;
	bottom: -13px;
	height: 1.9rem;
	left: 8px;
	opacity: 1;
	position: absolute;
	bottom: -2px;
	transition: opacity var(--p-motion-duration-150) var(--p-motion-ease-out);
	width: 1.3125rem;
}

.show-arrow-head::before {
	content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='27' fill='none'%3E%3Cpath fill='%23B5B5B5' d='M19 14.25h.75v1.5H19v-1.5Zm-8.923-.888.668-.34-.668.34Zm1.311 1.311.34-.668-.34.668ZM10.5 0v10.2H9V0h1.5Zm4.05 14.25H19v1.5h-4.45v-1.5ZM10.5 10.2c0 .853 0 1.447.038 1.91.037.453.107.714.207.912l-1.336.68c-.227-.444-.321-.924-.366-1.47C9 11.694 9 11.027 9 10.2h1.5Zm4.05 5.55c-.828 0-1.494 0-2.032-.043-.546-.045-1.026-.14-1.47-.366l.68-1.336c.198.1.459.17.913.207.462.037 1.056.038 1.909.038v1.5Zm-3.805-2.729c.216.424.56.768.984.984l-.681 1.336a3.75 3.75 0 0 1-1.64-1.638l1.337-.681Z'/%3E%3Cpath stroke='%23B5B5B5' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m17 12 3 3-3 3'/%3E%3C/svg%3E");
	opacity: 1;
	position: absolute;
}
.activeParent {
	background-color: #062448; /* Set the background color for the active parent item */
	border-left: 3px solid #0a84fe !important;
}
/* .activeParent::before {
	content: "";
	border-left: 2px solid red;
} */
.navBar {
	width: 3px;
	background-color: red;
	border-radius: 0 2px 2px 0;
}

.CircularProgressbar-trail {
	stroke: #e8ebf3 !important;
}
div#lg-topbar {
	padding-right: 0px;
}

.css-r8u8y9 {
	padding: 0 !important;
}

.smsButton:hover {
	background-color: #0c84fe;
	color: #fff;
}
.conversation_status {
	/* font-family: "SF Pro Display"; */
	font-size: 12px;
	font-weight: 550;
	font-style: normal;
	line-height: normal;
}

.Awaiting_Response {
	border-radius: 4px;
	padding: 5px 15px;
	color: #596176;
	background: #edeef2;
}
.Customer_Replied {
	border-radius: 4px;
	padding: 5px 15px;
	color: rgb(33, 150, 243);
	background: #EEF6FF;
}
.Discount_Offered {
	border-radius: 4px;
	padding: 5px 15px;
	color: #622bca;
	background: #f0e9ff;
}
.Ordered {
	border-radius: 4px;
	padding: 5px 15px;
	color: #05925a;
	background: #dbfbee;
}
.Cart_Viewed {
	border-radius: 4px;
	padding: 5px 15px;
	background: #fff8e8;
	color: #b87e0e;
}
.Closed {
	border-radius: 4px;
	padding: 5px 15px;
	color: #900DAA;
	background: #9500b714;
}
.Awaiting_Response_Dark {
	border-radius: 4px;
	padding: 5px 15px;
	color: #b9baca;
	background: #1f212d;
}
.Customer_Replied_Dark {
	border-radius: 4px;
	padding: 5px 15px;
	color: rgb(33, 150, 243);
	background: #1f212d;
}
.Discount_Offered_Dark {
	border-radius: 4px;
	padding: 5px 15px;
	color: #9963fe;
	background: #211a3a;
}
.Ordered_Dark {
	border-radius: 4px;
	padding: 5px 15px;
	color: #0fe38f;
	background: #0c2d29;
}
.Cart_Viewed_Dark {
	border-radius: 4px;
	padding: 5px 15px;
	background: #302923;
	color: #ffcb65;
}
.MuiDataGrid-autosizing {
	width: auto;
}

.message {
	border-radius: 20px;
	background: rgba(12, 132, 254, 0.1);
}
.message::before {
	right: -7px;
	width: 20px;
	/* background-color: rgb(227 238 255); */
	border-bottom-left-radius: 16px 14px;
}
.message::after {
	right: -9px;
	width: 9px;
	/* background-color: #f9fbff; */
	border-bottom-left-radius: 10px;
}

#LockedPage {
	position: static !important;
	background-color: transparent;
}
#connect-modal {
	/* position: static !important; */
	background-color: transparent;
}

.reply {
	background: #000d3130;
	border-radius: 20px;
}
.reply::before {
	left: -5px;
	width: 15px;
	background-color: #ebf0f4;
	border-bottom-right-radius: 16px 14px;
}
.reply::after {
	left: -9px;
	width: 9px;
	background-color: #f9fbff;
	border-bottom-right-radius: 10px;
}
.custom-message {
	position: relative;
}
.custom-message::before,
.custom-message::after {
	content: "";
	position: absolute;
	bottom: -3px;
	height: 25px;
}

.IPhone13 {
	background-image: url("../public/iphone.svg");
	width: 470px;
	height: 630px;
	background-repeat: no-repeat;
}
.viewOnShopify {
	border-radius: 4px;
	border: 1px solid #e8ebf3;
	padding: 5px 20px;
	background: #e8ebf3;
}

.searchPopoverOptions:hover {
	background-color: #404148;
}
.searchclass.css-17xnr36 {
	margin-left: 25px;
}
/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1ch45yb-MuiPaper-root-MuiPopover-paper {
    margin-left: -1.1%;
} */
.react-switch-handle {
	/* right: 1px !important ; */
	top: 3px !important;
	width: 14px !important;
	height: 14px !important;
	color: red;
}
.react-switch-handle.checked {
	transform: translateX(
		89px
	); /* Adjust the translation value based on your design */
}
.react-switch-bg {
	transition: transform 0.9s ease;
	height: 20px !important;
	width: 39px !important;
}

.switch-icon {
	transition: transform 0.3s ease;
}

.switch-icon.checked {
	transform: translateX(
		20px
	); /* Adjust the translation value based on your design */
}

.switch-icon.unchecked {
	transform: translateX(0);
}
.handle-dark .react-switch-handle {
	margin-left: -7px !important;
}
.handle-light .react-switch-handle {
	margin-left: 2px !important;
}

.MuiDataGrid-columnHeader--showColumnBorder {
	border: 1px solid red;
}

.cart-recorvery-Ellipse {
	width: 8px;
	border-radius: 50%;
	height: 8px;
	margin-right: 5px;
}
.conversation-Ellipse {
	background: #0c84fe;
}
.replies-Ellipse {
	background: #9963fe;
}
.orders-Ellipse {
	background: #0cd16a;
}
/* .conversationTable,
.Order-table {
	width: 99.5%;
} */
/* .conversationTable .ant-table-thead th:last-child {
	border-start-end-radius: 8px !important; 
  }
.conversationTable .ant-table-thead th:first-child {
	border-start-start-radius: 8px !important; 
  } */

.conversationTable .ant-pagination,
.Campaigns-table .ant-pagination {
	margin: 0 !important;
	padding: 7px 10px 1px 10px;
}

.funnel-chart-first .recharts-surface {
	border-bottom-left-radius: 11px;
}
.funnel-chart-last .recharts-surface {
	border-bottom-right-radius: 11px;
}
#loading-animation {
	/* left: 55%; */
}

.tooltip-container {
	position: absolute;
	width: 150px;
	left: -65px;
	z-index: 50;
	bottom: 19px;
}

.mobile-search-cut-icon {
	position: absolute;
	right: 5px;
	top: 10px;
}
.subscriber-graph .tooltip .subscriber {
	background-color: #0c84fe;
	display: block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
}
.tooltip .conversations {
	background-color: #0c84fe;
	display: block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
}
.tooltip .replies {
	background-color: #9963fe;
	width: 10px;
	height: 10px;
	display: block;
	border-radius: 50%;
}
.tooltip .orders {
	background-color: #0cd16a;
	width: 10px;
	height: 10px;
	display: block;
	border-radius: 50%;
}
.tooltip .tooltip-text {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.tooltip .text {
	background-color: #9963fe;
	width: 10px;
	height: 10px;
	display: block;
	border-radius: 50%;
}
.tooltip .revenue {
	background-color: #0c84fe;
	width: 10px;
	height: 10px;
	display: block;
	border-radius: 50%;
}

#affilate_table .affiliate_plan {
	font-size: 12px;
	font-weight: 550;
	font-style: normal;
	line-height: normal;
}
#affilate_table .free_plan {
	border-radius: 4px;
	padding: 5px 15px;
	/* background: #EDEDEE;
	color: #000D31; */
	color: #596176;
	background: #edeef2;
}
#affilate_table .starter_plan {
	border-radius: 4px;
	padding: 5px 15px;
	color: #05925a;
	background: #dbfbee;
}
#affilate_table .growth_plan {
	border-radius: 4px;
	padding: 5px 15px;
	background: #d9e8ff;
	color: #0b59a8;
}
#affilate_table .pro_plan {
	border-radius: 4px;
	padding: 5px 15px;
	color: #622bca;
	background: #f0e9ff;
}
#affilate_table .free_plan_dark {
	border-radius: 4px;
	padding: 5px 15px;
	color: #b9baca;
	background: #1f212d;
}
#affilate_table .starter_plan_dark {
	border-radius: 4px;
	padding: 5px 15px;
	color: #0fe38f;
	background: #0c2d29;
}
#affilate_table .growth_plan_dark {
	border-radius: 4px;
	padding: 5px 15px;
	color: #0c84fe;
	background: #0b1f3a;
}
#affilate_table .pro_plan_dark {
	border-radius: 4px;
	padding: 5px 15px;
	/* background: #1C173A;
	color: #FFF; */
	color: #9963fe;
	background: #211a3a;
}

.campaigns_status {
	/* font-family: "SF Pro Display"; */
	font-size: 12px;
	font-weight: 550;
	font-style: normal;
	line-height: normal;
}

.Sent {
	border-radius: 4px;
	padding: 5px 15px;
	color: #05925a;
	background: #dbfbee;
}
.Scheduled {
	border-radius: 4px;
	padding: 5px 15px;
	color: #0b59a8;
	background: #dbedff;
}
.Sending {
	border-radius: 4px;
	padding: 5px 15px;
	background: #fff8e8;
	color: #b87e0e;
}
.Draft {
	border-radius: 4px;
	padding: 5px 15px;
	color: #596176;
	background: #edeef2;
}
.Error {
	border-radius: 4px;
	padding: 5px 15px;
	color: #b51629;
	background: #ffe3e7;
}

.Sent_Dark {
	border-radius: 4px;
	padding: 5px 15px;
	color: #0fe38f;
	background: #13302d;
}
.Scheduled_Dark {
	border-radius: 4px;
	padding: 5px 15px;
	color: #0c84fe;
	background: #0b1f3a;
}
.Sending_Dark {
	border-radius: 4px;
	padding: 5px 15px;
	background: #302414;
	color: #ffa800;
}
.Draft_Dark {
	border-radius: 4px;
	padding: 5px 15px;
	color: #b9baca;
	background: #202b40;
}
.Error_Dark {
	border-radius: 4px;
	padding: 5px 15px;
	color: #ff465c;
	background: #301621;
}

.newtest {
	transform: translateY(0);
	transition: 0.1s;
}

.animatedBox {
	visibility: hidden;
	height: 0;
	/* transition: 0.3s; */
}

.campaign-planner-container:hover .newtest {
	transform: translateY(-100%);
	/* height: 0px; */
}

.campaign-planner-container:hover .animatedBox {
	visibility: visible;
	position: absolute;
	/* top: 22px; */
	transition: 0.3s;
	height: 100;
}

.campaign-planner-container:hover .here {
	position: absolute;
	width: 100%;
	bottom: 0;
}
.campaign-planner-container:hover .campaign-planner-heading {
	padding-top: 12px;
}
.ABtesting-section {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease;
}
.ABtesting-section.visible {
	max-height: 500px;
}
.Schedule-for-later-section {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease;
}
.Schedule-for-later-section.visible {
	max-height: 500px;
}

/* .ABtesting-section.hidden {
	height: 0px;
	min-height: 0px;
	visibility: hidden;
	overflow: hidden;
	transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transition-duration: 222ms;
} */
/* .ABtesting-section.hidden {
	height: 0px;
	min-height: 0px;
	visibility: hidden;
	overflow: hidden;
	transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transition-duration: 222ms;
}
.ABtesting-section.visible {
	height: auto;
	min-height: 0px;
	overflow: visible;
	transition-duration: 222ms;
	transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
} */
.dashed-border {
	border-style: dashed !important;
	/* background-image: url('/public/img/users/add.svg'); */
}
.seachBox.css-107s24p {
	margin-left: 50px;
}
#search-popup-parent {
	margin-left: -22px !important;
	/* margin-top: 20px; */
}

.Canny_Badge{
	display:none!important;
}

.users-roles-Admin {
	background-color: #edf6ff;
}
.users-roles-Admin-dark {
	background-color: rgba(237, 246, 255, 0.2);
}
.users-roles-Marketer {
	background-color: rgba(255, 70, 159, 0.15);
}
.users-roles-Marketer-dark {
	background-color: rgba(255, 70, 159, 0.15);
}
.subscription-status-Active {
	background-color: rgb(153, 99, 254, 0.15);
	color: #a474ff;
}
.subscription-status-Cancelled {
	background-color: rgb(255, 0, 76, 0.15);
	color: #ff004c;
}
.billing-status-Paid {
	background-color: #dbfbee;
	color: #05925a;
}
.billing-status-Pending {
	background-color: #edeff2;
	color: #596176;
}
.billing-status-Posted {
	background-color: rgba(12, 132, 254, 0.15);
	color: #0b59a8;
}
.billing-status-Paid-dark {
	background-color: #13302d;
	color: #0fe38f;
}
.billing-status-Pending-dark {
	background-color: #202b40;
	color: #b9baca;
}
.billing-status-Posted-dark {
	background-color: #0b1f3a;
	color: #0c84fe;
}
.react-datepicker__month-container {
	width: 334px;
	float: left;
}
.react-datepicker__navigation--previous {
	left: 20px !important;
	top: 20px !important;
}
.react-datepicker__navigation--next {
	right: 20px !important;
	top: 20px !important;
}
.react-datepicker__day-names {
	white-space: nowrap;
	/* margin-bottom: -8px; */
	margin-top: 20px;
}
.btn-container.MuiBox-root.css-0 {
	width: 100% !important;
	display: flex;
	justify-content: center;
	margin: 0 50px;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	margin: 9px !important;
}
.react-datepicker__navigation--previous {
	border-radius: 8px 0px 0px 8px !important;
	color: #0c1b2f !important;
}
.react-datepicker__navigation--next {
	border-radius: 0px 8px 8px 0px !important;
	color: #0c1b2f;
}
.react-datepicker__current-month {
	line-height: 3em;
	font-size: 20px;
	font-weight: 600;
}
.react-datepicker__navigation-icon::before {
	border-color: #0a0a18 !important;
}
.knowledge-editor {
	height: 345px;
	border-radius: "12px";
	color: "#000D31" !important;
}
.IconButton__root___3tqZW {
	padding-left: 14px !important;
}
.knowledge-editor .RichTextEditor__editor___1QqIU .public-DraftEditor-content {
	font-size: 14px !important;
	line-height: normal !important;
	font-weight: 400 !important;
}
.knowledge-editor-toolbar {
	padding: 10px !important;
	margin: 0px !important;
}
.knowledge-editor-toolbar .Button__root___1gz0c {
	border: none !important;
}
.card-bar {
	content: "";
	width: 5px;
	background: #0c84fe;
	height: 64px;
	margin-top: 14px;
	border-radius: 0px 6px 6px 0px;
	/* border-left: 5px solid red; */
}
/* styles.css */

.onboarding-loader {
	animation: rotate 1s linear infinite; /* Adjust the duration and timing function as needed */
}
.inbox-reply-box .react-input-emoji--container {
	min-height: 100px;
	margin: 0;
}
.inbox-reply-box .react-emoji-picker--container {
	top: 90px;
	left: 18px;
}
.inbox-reply-box .react-emoji-picker--wrapper {
	/* height: 243px; */
	height: 250px;
	border: 1px solid #F0F1F3;
	border-radius: 8px;
}
.inbox-reply-box .react-input-emoji--input {
	/* min-height: 100px !important; */
	max-height: 500px !important;
	margin: 0;
	/* padding: 9px 12px 33px !important; */
}

.inbox-reply-box .react-emoji {
	flex-direction: column;
	align-items: start;
	/* height: 100px;
	margin: 0 ; */
}
.inbox-reply-box .react-input-emoji--button {
	position: absolute;
	bottom: -30px;
	left: 15px;
}

.inbox-reply-box .react-emoji-picker--wrapper {
	left: 0 !important;
}
.knowledge-base-editor-output .ql-toolbar.ql-snow {
	/* display: none !important; */
	border: none !important;
}
.knowledge-base-editor-output .ql-editor {
	border: none !important;
}
.knowledge-base-editor-output .ql-editor {
	/* border: none !important; */
	min-height: 300px !important;
	max-height: 300px !important;
	overflow: hidden;
	overflow-y: scroll;
}
.txt-cart-text {
	color: #0081ff;
	/* Add any additional styling properties as needed */
}
form input {
	max-width: 550px;
	width: 100%;
	height: 45px;
	background: #ffffff;
	border: 1px solid #d0d0d0;
	border-radius: 4px;
	/* font-family: Karla; */
	font-size: 20px;
	color: #b7b7b7;
	padding: 0 25px;
	transition: all 0.3s ease-in-out 0s;
	margin: auto;
	margin-bottom: 30px;
}
.ql-editor {
	margin-top: 2px;
	min-height: 300px;
	max-height: 300px;
	overflow: hidden;
	overflow-y: scroll;
}
.ql-editor p {
	width: 300px;
}
.create-campaign-text .ql-editor {
	max-height: 200px !important;
	min-height: 200px !important;
}
 .ql-mention-list-container{
	display: none !important;
}
.create-campaign-text .ql-toolbar .ql-image {
	/* position: absolute !important;
	bottom: 95px !important; */
}

.text-example ::-webkit-scrollbar {
	display: none;
}

.ant-spin-dot{
	margin: 0px !important;
	top: 45% !important;
}

.mobile-phone::-webkit-scrollbar{
	display: none;
}

.create-campaign-text .ql-editor p {
	width: 100%;
}
.analytics-ellipse .cart-Recovery-ellipse {
	background-color: #0081ff;
	width: 8px;
	height: 8px;
	border-radius: 100px;
}
.analytics-ellipse .campaigns-ellipse {
	background-color: #9963fe;
	width: 8px;
	height: 8px;
	border-radius: 100px;
}

.redar-tooltip-text .smsReceived {
	background-color: #9963fe;
	width: 8px;
	height: 8px;
	border-radius: 100px;
}
.redar-tooltip-text .smsSent {
	background-color: #0081ff;
	width: 8px;
	height: 8px;
	border-radius: 100px;
}

.redar-tooltip .redar-tooltip-text {
	display: flex;
}
.total-sales-tooltip .total-sales-tooltip-text {
	display: flex;
}
.total-sales-tooltip-text .campaigns {
	background-color: #9963fe;
	width: 8px;
	height: 8px;
	border-radius: 100px;
}
.total-sales-tooltip-text .cartRecovery {
	background-color: #0081ff;
	width: 8px;
	height: 8px;
	border-radius: 100px;
}

.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

.reports-analytics .status {
	width: 88px;
	height: 22px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	font-weight: 550;
	font-size: 12px;
}
.reports-analytics .Progress {
	background: rgb(255, 168, 0, 0.15);
	color: #b87e0e;
}
.reports-analytics .Progress-dark {
	background: rgb(255, 168, 0, 0.15);
	color: #b87e0e;
}
.reports-analytics .Complete {
	background: #dbfbee;
	color: #05925a;
}
.reports-analytics .Complete-dark {
	background: #13302d;
	color: #05925a;
}
.insight-ellipse .cart-Recovery-ellipse {
	background-color: #0081ff;
	width: 8px;
	height: 8px;
	border-radius: 100px;
}
.insight-ellipse .automations-ellipse {
	background-color: #ff5c00;
	width: 8px;
	height: 8px;
	border-radius: 100px;
}
.insight-ellipse .campaigns-ellipse {
	background-color: #9963fe;
	width: 8px;
	height: 8px;
	border-radius: 100px;
}
.insight-ellipse .flows-ellipse {
	background-color: #ff00a8;
	width: 8px;
	height: 8px;
	border-radius: 100px;
}
.insight-graph-tooltip .insight-graph-tooltip-text {
	display: flex;
}
.insight-graph-tooltip-text .campaigns {
	background-color: #9963fe;
	width: 8px;
	height: 8px;
	border-radius: 100px;
}
.insight-graph-tooltip .cartRecovery {
	background-color: #0081ff;
	width: 8px;
	height: 8px;
	border-radius: 100px;
}
.insight-graph-tooltip .flow {
	background-color: #FF00A8;
	width: 8px;
	height: 8px;
	border-radius: 100px;
}
.insight-graph-tooltip .automation {
	background-color: #FF5C00;
	width: 8px;
	height: 8px;
	border-radius: 100px;
}
/* .apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
    margin-top: 10px;
} */
/* .apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
	position: absolute;
    left: auto;
    top: 34px !important;
    right: 5px;
} */
.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
    margin-top: 16px;
}
p.MuiTypography-root.MuiTypography-body1.MuiTypography-alignRight.css-jiak7z-MuiTypography-root {
    font-weight: 600;
}
.apexcharts-datalabel-label {
    font-size: 10px;
	color: #e7e7e7;
}
.ant-select-item-option-grouped{
	padding-inline-start  :12px !important
}
#create_segments .react-datepicker__input-container input {
	padding-left: 30px !important;
}
@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.no-scrollbar {
	overflow: auto; /* Enables scrolling */
	-ms-overflow-style: none; /* Hides scrollbar for IE and Edge */
	scrollbar-width: none; /* Hides scrollbar for Firefox */
}

.no-scrollbar::-webkit-scrollbar {
	display: none; /* Hides scrollbar for Chrome, Safari, and Opera */
}
.react-input-emoji--button{
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuODcyODUgMTMuMDAxOUM3LjY3NDY2IDEyLjgzOTMgNy41MTcwOSAxMi42Njg5IDcuMzk2NTMgMTIuNTAwMkgxMi42MDQ4QzEyLjQ4NDIgMTIuNjY4OSAxMi4zMjY2IDEyLjgzOTMgMTIuMTI4NSAxMy4wMDE5QzExLjU5MjggMTMuNDQxNCAxMC44NDAxIDEzLjc1MDIgMTAuMDAwNyAxMy43NTAyQzkuMTYxMTcgMTMuNzUwMiA4LjQwODQ2IDEzLjQ0MTQgNy44NzI4NSAxMy4wMDE5WiIgZmlsbD0iIzgwODY5OCIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAuODMzOTg0IDEwLjAwMDJDMC44MzM5ODQgNC45Mzc1NSA0LjkzODA0IDAuODMzNDk2IDEwLjAwMDcgMC44MzM0OTZDMTUuMDYzMyAwLjgzMzQ5NiAxOS4xNjczIDQuOTM3NTUgMTkuMTY3MyAxMC4wMDAyQzE5LjE2NzMgMTUuMDYyOCAxNS4wNjMzIDE5LjE2NjggMTAuMDAwNyAxOS4xNjY4QzQuOTM4MDQgMTkuMTY2OCAwLjgzMzk4NCAxNS4wNjI4IDAuODMzOTg0IDEwLjAwMDJaTTcuNTAwNjYgNi4yNTAxNkM2LjgxMDMgNi4yNTAxNiA2LjI1MDY2IDYuODA5ODEgNi4yNTA2NiA3LjUwMDE2QzYuMjUwNjYgOC4xOTA1MiA2LjgxMDMgOC43NTAxNiA3LjUwMDY2IDguNzUwMTZDOC4xOTEwMSA4Ljc1MDE2IDguNzUwNjYgOC4xOTA1MiA4Ljc1MDY2IDcuNTAwMTZDOC43NTA2NiA2LjgwOTgxIDguMTkxMDEgNi4yNTAxNiA3LjUwMDY2IDYuMjUwMTZaTTEyLjUwMDcgNi4yNTAxNkMxMS44MTAzIDYuMjUwMTYgMTEuMjUwNyA2LjgwOTgxIDExLjI1MDcgNy41MDAxNkMxMS4yNTA3IDguMTkwNTIgMTEuODEwMyA4Ljc1MDE2IDEyLjUwMDcgOC43NTAxNkMxMy4xOTEgOC43NTAxNiAxMy43NTA3IDguMTkwNTIgMTMuNzUwNyA3LjUwMDE2QzEzLjc1MDcgNi44MDk4MSAxMy4xOTEgNi4yNTAxNiAxMi41MDA3IDYuMjUwMTZaTTYuMjUwNjUgMTAuODMzNUM1Ljc5MDQxIDEwLjgzMzUgNS40MTczMiAxMS4yMDY2IDUuNDE3MzIgMTEuNjY2OEM1LjQxNzMyIDEyLjY4NzIgNi4wMTM2MiAxMy42MzIyIDYuODE1NjQgMTQuMjkwM0M3LjYzNDA5IDE0Ljk2MTkgOC43NTYzOSAxNS40MTY4IDEwLjAwMDcgMTUuNDE2OEMxMS4yNDQ5IDE1LjQxNjggMTIuMzY3MiAxNC45NjE5IDEzLjE4NTcgMTQuMjkwM0MxMy45ODc3IDEzLjYzMjIgMTQuNTg0IDEyLjY4NzIgMTQuNTg0IDExLjY2NjhDMTQuNTg0IDExLjIwNjYgMTQuMjEwOSAxMC44MzM1IDEzLjc1MDcgMTAuODMzNUg2LjI1MDY1WiIgZmlsbD0iIzgwODY5OCIvPgo8L3N2Zz4K') !important;
	background-repeat:no-repeat !important

}
 .react-input-emoji--button--icon path {
	display: none;
}

 .flex-column {
border: 1px solid red !important;
}

@media screen and (min-width: 2701px) {
	/* Your styles for screens above 2500 pixels wide go here */
	/* .loading-animation {
		left: 50% !important;
	} */
	#search-popup {
		width: 970px !important;
	}
	#search-popup-parent {
		margin-left: -22px !important;
	}
	.myActive::before {
		left: 7.61%;
	}
}

@media screen and (min-width: 2501px) and (max-width: 2700px) {
	/* #loading-animation {
		left: 50% !important;
	} */
	/* Your styles for screens above 2500 pixels wide go here */
	#search-popup {
		width: 750px !important;
	}
	#search-popup-parent {
		margin-left: -7px !important;
	}
	.myActive::before {
		left: 7.8%;
	}
}
@media screen and (min-width: 2001px) and (max-width: 2500px) {
	#loading-animation {
		/* left: 55% !important; */
	}
	/* Your styles for screens above 2500 pixels wide go here */
	#search-popup {
		width: 650px !important;
	}
	#search-popup-parent {
		margin-left: -25px !important;
	}
	.myActive::before {
		left: 7.8%;
	}
}
@media screen and (min-width: 1901px) and (max-width: 2000px) {
	.myActive::before {
		left: 7.392%;
	}
}
@media screen and (min-width: 1801px) and (max-width: 1900px) {
	.myActive::before {
		left: 7.5%;
	}
}
@media screen and (min-width: 1600px) and (max-width: 1800px) {
	.myActive::before {
		left: 7.202%;
	}
}

@media screen and (min-width: 1600px) and (max-width: 1900px) {
	/* Your styles for screens above 2500 pixels wide go here */

	#search-popup {
		width: 530px !important;
	}
	#search-popup-parent {
		margin-left: -24px !important;
		margin-top: 8px !important;
	}
}
@media screen and (min-width: 320px) and (max-width: 900px) {
	/* Your styles for screens above 2500 pixels wide go here */
	.conversationTable,
	.Order-table {
		/* width:250px !important; */
	}
}
@media screen and (min-width: 1656px) and (max-width: 1856px) {
	#templateCard {
		height: 160px;
	}
}
@media screen and (min-width: 1356px) and (max-width: 1655px) {
	#templateCard {
		height: 210px;
	}
}

@media screen and (min-width: 1200px) and (max-width: 1355px) {
	#templateCard {
		height: 256px;
	}
}
@media screen and (min-width: 1200px) and (max-width: 1350px) {
	.campaigns-planner-tag {
		flex-direction: column !important;
	}
	.campaigns-planner-tag .tag-name {
		width: 100%;
		text-align: center;
		margin-bottom: 10px;
	}
}
@media screen and (min-width: 1042px) and (max-width: 1382px) {
	.campaign-page-card {
		height: 450px !important;
	}
}

@font-face {
	font-family: NotoColorEmojiLimited;
	unicode-range: U+1F1E6-1F1FF;
	src: url(https://raw.githack.com/googlefonts/noto-emoji/main/fonts/NotoColorEmoji.ttf);
  }
