.videos {
	/* font-family: sans-serif; */
	/* align-items: center;
	display: flex;
	justify-content: center; */
	/* margin-top: 70px; */
}

.videos .images-container {
	display: flex;
	max-width: 100%;
	overflow: scroll;
	scroll-behavior: smooth;
	transition: scroll 0.3s ease-in-out;
    gap: 82px;
}
.videos .images-container::-webkit-scrollbar {
	display: none;
}
.videos .lastBtn {
    position: absolute;
    top: 32%;
    /* left: -50px; */
}
.videos .nextBtn{
	position: absolute;
    top: 30%;
    /* right: -50px;	 */
}

/* Hide scrollbar for IE, Edge and Firefox */
.videos .images-container {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.videos .image {
	/* width: 200px; */
	/* height: 200px; */
	/* margin-right: 20px; */
	border-radius: 8px;
}

.videos .nav-btn {
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	background-color: #c3c3c3;
	border-radius: 6px;
	height: 200px;
	width: 30px;
	margin: 5px;
}
